var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.allowed)?_c('div',{staticClass:"w-full flex flex-col"},[_c('span',{staticClass:"text-xl text-gray-700 font-semibold mb-4 text-left"},[_vm._v("Reservas futuras")]),_c('table-component',{staticClass:"mb-8",attrs:{"loading":_vm.loading,"head":[
      { key: 'code', label: 'Código reserva' },
      { key: 'name', label: 'Nombre paciente' },
      { key: 'date', label: 'fecha atención' },
      { key: 'value', label: 'Costo reserva' },
      { key: 'status', label: 'Estado' } ],"body":_vm.bodyFuture}}),_c('span',{staticClass:"text-xl text-gray-700 font-semibold mb-4 text-left"},[_vm._v("Reservas pasadas")]),_c('table-component',{staticClass:"mb-8",attrs:{"loading":_vm.loading,"head":[
      { key: 'code', label: 'Código reserva' },
      { key: 'name', label: 'Nombre paciente' },
      { key: 'date', label: 'fecha atención' },
      { key: 'value', label: 'Costo reserva' },
      { key: 'status', label: 'Estado' } ],"body":_vm.bodyPast}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }